const messages = {
  login: {
    form_username: 'Username',
    form_password: 'Password',
    form_see_password: 'See password',
    form_login_button: 'Login',
    forgot_password_action: 'Forgot your password?',
    error_badrequest_login: 'Invalid email format.',
    error_empty_field: 'This field can not be empty.',
    error_invalid_email_format: 'Invalid email format.',
    error_forbidden_login: 'The specified user does not have access to Visualfy Places or your credentials are wrong.',
    error_400_redirect: 'Petition can not be proccessed and you have been logged out.',
    error_401_redirect: 'Your session has expired.',
    error_403_redirect: 'You do not have the necessary permissions to access.',
    code_input_label: 'Code',
    new_code_label: 'Resend code',
    code_resend_success: 'Code sent successfully.',
    code_login_error: 'Invalid code',
  },
  resetpassword: {
    form_reset_button: 'Send recovery link',
    success_password_recovery: 'An email has been sent to the specified address with instructions.',
    error_409_alert_text: 'It looks like you already have a pending recovery link, check the email.',
    go_back_to_login: 'Back',
  },
  dashboard: {
    title_label: 'Dashboard',
    restore_scene_button_text: 'Restore Hue Scene',
    restore_scene_button_tooltip: 'It will set the lamps to their default state.',
    restore_scene_success: 'Restored Hue Scene successfully',
    not_found_error: 'An error occurred. Try again later',
    devices_table_title: 'Devices',
  },
  navigation: {
    dashboard: 'Dashboard',
    notifications: 'Custom Notifications',
    services: 'Closing Services',
    closing_doors: 'Closing Doors',
    emergency: 'Emergency',
    alert_logs: 'Alert Logs',
    users: 'Users',
    demo: 'Demo',
    loudspeaker: 'Loudspeaker Alert',
  },
  menu: {
    dark_mode_label: 'Dark Mode',
    time_zone_label: 'Select Time Zone',
    time_zone_europe: 'Europe/Madrid',
    time_zone_atlantic: 'Atlantic/Canary',
    language_label: 'Select language',
    building_label: 'Select building',
    profile_label: 'Profile',
    logout_label: 'Disconnect',
    success_language_change: 'Language changed',
    error_language_change: 'Error changing language',
    success_building_change: 'Building changed',
    error_building_change: 'Error changing building',
    success_timezone_change: 'Timezone changed',
    error_timezone_change: 'Error changing timezone',
    configuration_header: 'Settings',
    preferences_header: 'Preferences',
  },
  profile: {
    buildings_manage_header: 'Buildings you manage',
    building_users_tooltip: 'Users',
    account_management_header: 'Account management',
    edit_password_button_text: 'Change password',
    current_password_label: 'Current password',
    current_password_placeholder: 'Enter current password',
    new_password_label: 'New password',
    new_password_placeholder: 'Enter new password',
    new_password_description: 'Remember: It cannot be the same as the current password.',
    show_password: 'Show password',
    edit_password_submit_button_text: 'Confirm',
    change_password_same_error: 'Passwords cannot match',
    change_password_too_short: 'The password cannot be shorter than eight characters',
    change_password_bad_request: 'Your petition could not be processed',
    change_password_unavailable: 'Servers are not available. Please, try again later',
    change_password_success: 'Password changed successfully.',
    change_password_current_error: 'The current password is incorrect',
  },
  custom_alerts: {
    title_label: 'Alerts/{0}',
    failed_to_load_props_error: 'Page failed to load, please try again or contact support.',
    table_header_title: 'Title',
    table_header_body: 'Body',
    table_header_time: 'Time',
    table_header_days: 'Days',
    table_header_actions: 'Actions',
    error_empty_field: 'This field can not be empty.',
    failed_to_load_building_store: 'The building information could not be loaded, go back to the menu to select it.',
    failed_to_load_modal: 'The alert creation could not be accessed, please try again later.',
    modal_title: 'Create {0} alert',
    modal_close_button: 'Close',
    modal_create_button: 'Create',
    modal_day_picker_label: 'Select days',
    failed_to_create_alert: 'The alert could not be created, please try again later.',
    failed_to_create_alert_not_in_building: 'The alert could not be created. This alert is not available in the selected building.',
    alert_created: 'The alert has been successfully created.',
    modal_delete_title: 'Remove {0} alert',
    modal_delete_button: 'Remove',
    failed_to_perform_modal_action: 'An error occurred while performing the action.',
    failed_to_delete_alert: 'An error occurred while deleting the alert. Try again latter.',
    alert_deleted: 'Alert removed successfully.',
    failed_to_edit_alert: 'An error occurred while modifying the alert. Try again latter.',
    modal_edit_button: 'Save changes',
    alert_edited: 'Alert modified successfully.',
    remove_time_label: 'Remove time',
    failed_to_activate_alert: 'An error occurred while activating the alert. Try again latter.',
    alert_activated: 'Alert sent',
    modal_activate_button: 'Send',
    send_alert_disclaimer: 'Pressing the Send button will send a message to all users subscribed to the building who have the alert activated.',
    modal_activate_title: 'Send {0} alert',
    modal_edit_title: 'Modify {0} alert',
  },
  dss_info: {
    device: 'Device',
    last_connection: 'Last connection',
    state: 'State',
    ok: 'Online',
    issues: 'Issues',
    disconnected: 'Disconnected',
    unknown: 'Unknown',
  },
  users: {
    error_getting_users: 'The user list could not be retrieved, please try again.',
    modal_title: 'Add a new user',
    name_title: 'Name',
    last_name_title: 'Last name',
    email_title: 'Email',
    role_title: 'Role',
    select_role_label: 'Select a role',
    owner_label: 'Owner',
    global_manager_label: 'Global manager',
    building_manager_label: 'Building manager',
    user_label: 'User',
    notes_title: 'Notes (optional)',
    close_button: 'Close',
    register_user_button: 'Register user',
    error_badrequest_create_user: 'Petition is not well formed, please try again',
    error_conflict: 'User is already registered',
    register_successful: 'User registered successfully',
  },
  loudspeaker: {
    title: 'Loudspeaker Alert',
    connection_accepted: 'Established connection succesfully',
    awaiting_connection: 'Not connected. Awaiting service connection...',
    awaiting_status: 'Connected. Awaiting service status...',
    disconnected: 'Disconnected from service',
    not_connected: 'An error has occurred. Please, try to reload this page.',
    no_recognition: 'Your message could not be recognized.',
    timeout: 'Timeout. Service has been reloaded.',
    timeout_restart: 'Timeout. Press the "Start" button to continue or initialize a Loudspeaker Alert.',
    long_timeout_restart: 'Timeout. Send your Loudspeaker Alert or refresh this page.',
    connection_status_active: 'Speech To Text recognition is in progress',
    transcribing: 'Please speak now.',
    countdown: 'Transcription time left: ',
    review: 'Review your message and press Send',
    service_is_busy: 'Looks like the service is busy. Please, check it is not open on another window.',
    service_is_ready: 'Press the "Start" button to initialize a new Loudspeaker Alert.',
    notification_sent: 'Notification sent',
    server_not_available: 'Server is not available. Please try again later',
    bad_request: 'Invalid message format',
    'start_es-ES': 'Start: Spanish',
    'start_ca-ES': 'Start: Catalan',
    'start_gl-ES': 'Start: Galician',
    'start_eu-ES': 'Start: Basque',
    'start_en-GB': 'Start: English',
    pause: 'Pause',
    stop: 'Stop',
    send: 'Send',
    no_empty_loudspeaker_notification: 'Invalid message format. It is not possible to send messages without text.',
  },
  alerts: {
    services_label: 'Services',
    notifications_label: 'Notifications',
    closing_time_label: 'Closing Doors',
    appointment_label: 'Appointment',
    fire_label: 'Fire alarm',
    babe_label: 'Baby',
    bell_label: 'Bell',
    emergency_label: 'Emergency',
  },
  page_titles: {
    home_title: 'Dashboard',
    alerts_notifications_title: 'Notification Alerts',
    alerts_services_title: 'Services Alerts',
    alerts_closing_doors_title: 'Closing Doors Alerts',
    alerts_emergency_title: 'Emergency Alerts',
    alert_logs_title: 'Alert Logs',
    users_title: 'Users',
    profile_title: 'Profile',
    demo_title: 'Demo',
    loudspeaker_title: 'Loudspeaker Alert',
    login_title: 'Login',
    reset_password_title: 'Reset Password',
    two_factor_title: 'Two Factor Authentication',
  },
  time: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    monday_letter: 'Mon',
    tuesday_letter: 'Tue',
    wednesday_letter: 'Wed',
    thursday_letter: 'Thu',
    friday_letter: 'Fri',
    saturday_letter: 'Sat',
    sunday_letter: 'Sun',
  },
  datatable: {
    add_value_button: 'Create new',
    page_navigation_left: 'Previous',
    page_navigation_right: 'Next',
    rows_per_page_label: 'Rows per page:',
    of_between_pages_label: 'of',
    action_edit_label: 'Edit',
    action_delete_label: 'Delete',
    action_activate_label: 'Activate',
  },
  demo: {
    success_alert_sent: 'Alert sent successfully.',
    error_alert_sent: 'An error occurred while sending the alert, please try again.',
  },
  alert_logs: {
    table_header_date: 'Date',
    table_header_alert: 'Alert',
    table_header_location: 'Location',
    table_header_building: 'Building',
    title_label: 'Alert Log',
    unknown_label: 'Unknown',
  },
  server_not_available_error: 'Our server is currently unreachable, try again later.',
};

export default messages;
