import './styles/quasar.scss';
import lang from 'quasar/lang/es.js';
import Notify from 'quasar/src/plugins/Notify.js';;
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    dark: false,
  },
  plugins: {
    Notify,
  },
  lang,
};
