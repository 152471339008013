import { AxiosInstance } from 'axios';
import httpInstance from '@/services/HTTP/Visualfy/http-commons';
import UnauthorizedError from './Errors/UnauthorizedError';
import ServerNotAvailable from './Errors/ServerNotAvailable';
import NotFoundError from './Errors/NotFoundError';

class DemoDataService {
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  getDemo(building: string): Promise<unknown> {
    return this.http.get(`/api/v1/buildings/${building}/demo`)
      .then((result) => {
        if (result.data && result.data.status === 'success') {
          return result.data.data.items[0];
        }

        return [];
      })
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
              throw new UnauthorizedError();
            case 404:
              throw new NotFoundError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }
}

export default new DemoDataService(httpInstance);
