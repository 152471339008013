const messages = {
  login: {
    form_username: 'Usuario',
    form_password: 'Contraseña',
    form_see_password: 'Ver contraseña',
    form_login_button: 'Conectar',
    forgot_password_action: '¿Olvidaste tu contraseña?',
    error_badrequest_login: 'El formato del email no es válido.',
    error_empty_field: 'Este campo no puede estar vacío.',
    error_invalid_email_format: 'Formato del email inválido.',
    error_forbidden_login: 'El usuario especificado no tiene acceso a Visualfy Places o tus credenciales son erróneas.',
    error_400_redirect: 'La petición no se ha podido procesar y has sido desconectado.',
    error_401_redirect: 'La sesión ha caducado.',
    error_403_redirect: 'No tienes los permisos necesarios para acceder.',
    code_input_label: 'Código',
    new_code_label: 'Volver a envíar código',
    code_resend_success: 'Código enviado correctamente.',
    code_login_error: 'Código incorrecto',
  },
  resetpassword: {
    form_reset_button: 'Enviar enlace de recuperación',
    success_password_recovery: 'Se ha enviado un email a la dirección especificada con las instrucciones.',
    error_409_alert_text: 'Parece que ya tienes un enlace de recuperación pendiente, comprueba el email.',
    go_back_to_login: 'Volver',
  },
  dashboard: {
    title_label: 'Tablero',
    restore_scene_button_text: 'Restaurar escena de Hue',
    restore_scene_button_tooltip: 'Devolverá las lámparas a su estado predefinido.',
    restore_scene_success: 'Escena Hue restaurada con éxito',
    not_found_error: 'Ha ocurrido un error. Inténtalo más tarde',
    devices_table_title: 'Dispositivos',
  },
  navigation: {
    dashboard: 'Tablero',
    notifications: 'Notificaciones personalizadas',
    services: 'Cierre de servicios',
    closing_doors: 'Cierre de puertas',
    emergency: 'Alerta de Emergencia',
    loudspeaker: 'Alerta de megafonía',
    alert_logs: 'Registro de alertas',
    users: 'Usuarios',
    demo: 'Demo',
  },
  menu: {
    dark_mode_label: 'Modo oscuro',
    time_zone_label: 'Seleccionar zona horaria',
    time_zone_europe: 'Europa/Madrid',
    time_zone_atlantic: 'Atlántico/Canarias',
    language_label: 'Elegir idioma',
    building_label: 'Elegir edificio',
    profile_label: 'Perfil',
    logout_label: 'Desconectar',
    success_language_change: 'Idioma modificado',
    error_language_change: 'Error al cambiar el idioma',
    success_building_change: 'Edificio modificado',
    error_building_change: 'Error al cambiar el edificio',
    success_timezone_change: 'Zona horaria modificada',
    error_timezone_change: 'Error al modificar la zona horaria',
    configuration_header: 'Configuración',
    preferences_header: 'Preferencias',
  },
  profile: {
    buildings_manage_header: 'Edificios que gestionas',
    building_users_tooltip: 'Usuarios',
    account_management_header: 'Gestión de cuenta',
    edit_password_button_text: 'Modificar contraseña',
    current_password_label: 'Contraseña actual',
    current_password_placeholder: 'Introduce contraseña actual',
    new_password_label: 'Nueva contraseña',
    new_password_placeholder: 'Introduce nueva contraseña',
    new_password_description: 'Recuerda: No puede ser igual a la contraseña actual.',
    show_password: 'Mostrar contraseña',
    edit_password_submit_button_text: 'Confirmar',
    change_password_same_error: 'Las contraseñas no pueden coincidir',
    change_password_too_short: 'La contraseña no puede contener menos de ocho caracteres',
    change_password_bad_request: 'La petición no se ha podido procesar',
    change_password_unavailable: 'Servidores no disponibles. Inténtalo más tarde',
    change_password_success: 'La contraseña se ha modificado con éxito.',
    change_password_current_error: 'La contraseña actual no es correcta.',
  },
  custom_alerts: {
    title_label: 'Alertas/{0}',
    failed_to_load_props_error: 'No se ha podido cargar la página, vuelve a intentarlo o contacta con soporte.',
    table_header_title: 'Título',
    table_header_body: 'Mensaje',
    table_header_time: 'Hora',
    table_header_days: 'Días',
    table_header_actions: 'Acciones',
    error_empty_field: 'Este campo no puede estar vacío.',
    failed_to_load_building_store: 'No se ha podido cargar la información del edificio, vuelve al menú para seleccionarlo.',
    failed_to_load_modal: 'No se ha podido acceder a la creación de alertas, vuelve a intentarlo más tarde.',
    modal_title: 'Crear alerta de {0}',
    modal_close_button: 'Cerrar',
    modal_create_button: 'Crear',
    modal_day_picker_label: 'Seleccionar días',
    failed_to_create_alert: 'No se ha podido crear la alerta, vuelve a intentarlo más tarde.',
    failed_to_create_alert_not_in_building: 'No se ha podido crear la alerta. Esta alerta no está disponible en el edificio seleccionado.',
    alert_created: 'La alerta se ha creado correctamente.',
    modal_delete_title: 'Eliminar alerta de {0}',
    modal_delete_button: 'Eliminar',
    failed_to_perform_modal_action: 'Ocurrió un error al realizar la acción.',
    failed_to_delete_alert: 'Ocurrió un error al eliminar la alerta. Vuelve a intentarlo.',
    alert_deleted: 'Alerta eliminada con éxito.',
    failed_to_edit_alert: 'Ocurrió un error al modificar la alerta. Vuelve a intentarlo.',
    modal_edit_button: 'Guardar cambios',
    alert_edited: 'Alerta modificada con éxito.',
    remove_time_label: 'Eliminar hora',
    failed_to_activate_alert: 'Ocurrió un error al enviar la alerta. Vuelve a intentarlo.',
    alert_activated: 'Alerta enviada.',
    modal_activate_button: 'Enviar',
    send_alert_disclaimer: 'Al presionar el botón de Enviar le llegará un mensaje a todos los usuarios suscritos al edificio que tengan la alerta activada.',
    modal_activate_title: 'Enviar alerta de {0}',
    modal_edit_title: 'Modificar alerta de {0}',
  },
  dss_info: {
    device: 'Dispositivo',
    last_connection: 'Última conexión',
    state: 'Estado',
    ok: 'En línea',
    issues: 'Con problemas',
    disconnected: 'Desconectado',
    unknown: 'Desconocido',
  },
  users: {
    error_getting_users: 'No se ha podido recuperar la lista de usuarios, vuelve a intentarlo.',
    modal_title: 'Añadir un nuevo usuario',
    name_title: 'Nombre',
    last_name_title: 'Apellidos',
    email_title: 'Email',
    role_title: 'Rol',
    select_role_label: 'Selecciona un rol',
    owner_label: 'Propietario',
    global_manager_label: 'Gestor de empresa',
    building_manager_label: 'Gestor de edificio',
    user_label: 'Usuario',
    notes_title: 'Notas (opcional)',
    close_button: 'Cerrar',
    register_user_button: 'Registrar usuario',
    error_badrequest_create_user: 'La petición está mal formada. Vuelve a intentarlo.',
    error_conflict: 'El usuario ya está registrado',
    register_successful: 'Usuario registrado con éxito',
  },
  loudspeaker: {
    title: 'Alerta de megafonía',
    connection_accepted: 'Conexión establecida con éxito',
    awaiting_connection: 'No conectado. Esperando a recibir conexión...',
    awaiting_status: 'Conectado. Esperando a recibir estado del servicio...',
    disconnected: 'Desconectado del servicio',
    not_connected: 'Se ha producido un error. Prueba a recargar la página',
    no_recognition: 'No se ha podido reconocer tu mensaje.',
    timeout: 'Tiempo de espera excedido. Se ha recargado el servicio.',
    timeout_restart: 'Tiempo de espera excedido. Pulsa "Iniciar" para retomar o empezar la Alerta de Megafonía',
    long_timeout_restart: 'Tiempo de espera excedido. Envía la Alerta de Megafonía o recarga la página',
    connection_status_active: 'El reconocimiento de voz a texto está en marcha',
    transcribing: 'Por favor, habla ahora.',
    countdown: 'Tiempo restante de transcripción: ',
    review: 'Revisa tu mensaje y pulsa Enviar',
    service_is_busy: 'Parece que el servicio de reconocimiento de voz está ocupado. Comprueba que no esté abierto en otra ventana.',
    service_is_ready: 'Pulsa el botón "Iniciar" para empezar una nueva Alerta de Megafonía.',
    notification_sent: 'Notificación enviada',
    server_not_available: 'Servidor no disponible. Por favor, inténtalo más tarde',
    bad_request: 'Formato del mensaje no válido',
    'start_es-ES': 'Iniciar: Español',
    'start_ca-ES': 'Iniciar: Catalán',
    'start_gl-ES': 'Inciar: Gallego',
    'start_eu-ES': 'Iniciar: Euskera',
    'start_en-GB': 'Inciar: Inglés',
    pause: 'Pausar',
    stop: 'Detener',
    send: 'Enviar',
    no_empty_loudspeaker_notification: 'Formato del mensaje no válido. No es posible enviar mensajes sin texto.',
  },
  alerts: {
    services_label: 'Cierre de servicios',
    notifications_label: 'Notificaciones',
    closing_time_label: 'Cierre de puertas',
    appointment_label: 'Turno de visita',
    fire_label: 'Alarma de incendios',
    babe_label: 'Bebé',
    bell_label: 'Timbre',
    emergency_label: 'Emergencia',
  },
  page_titles: {
    home_title: 'Tablero',
    alerts_notifications_title: 'Alertas de notificaciones',
    alerts_services_title: 'Alertas de servicios',
    alerts_closing_doors_title: 'Alertas de cierre de puertas',
    alerts_emergency_title: 'Alertas de emergencia',
    alert_logs_title: 'Registro de alertas',
    users_title: 'Usuarios',
    profile_title: 'Perfil',
    demo_title: 'Demo',
    loudspeaker_title: 'Alerta de megafonía',
    login_title: 'Iniciar sesión',
    reset_password_title: 'Recuperar contraseña',
    two_factor_title: 'Autenticación de dos factores',
  },
  time: {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    monday_letter: 'L',
    tuesday_letter: 'M',
    wednesday_letter: 'X',
    thursday_letter: 'J',
    friday_letter: 'V',
    saturday_letter: 'S',
    sunday_letter: 'D',
  },
  datatable: {
    add_value_button: 'Crear',
    page_navigation_left: 'Anterior',
    page_navigation_right: 'Siguiente',
    rows_per_page_label: 'Filas por página:',
    of_between_pages_label: 'de',
    action_edit_label: 'Modificar',
    action_delete_label: 'Eliminar',
    action_activate_label: 'Activar',
  },
  demo: {
    success_alert_sent: 'Alerta enviada con éxito.',
    error_alert_sent: 'Ha ocurrido un error al enviar la alerta, porfavor vuelve a intentarlo.',
  },
  alert_logs: {
    table_header_date: 'Fecha',
    table_header_alert: 'Alerta',
    table_header_location: 'Lugar',
    table_header_building: 'Edificio',
    title_label: 'Registro de alertas pasadas',
    unknown_label: 'Desconocido',
  },
  server_not_available_error: 'No se puede acceder a nuestro servidor, vuelve a intentarlo.',
};

export default messages;
