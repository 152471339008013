import { ref } from 'vue';
import { defineStore } from 'pinia';
import { Building } from '@/types/Building';

// eslint-disable-next-line import/prefer-default-export
export const useBuildings = defineStore('Buildings', () => {
  const lastFetch = ref(undefined as Date | undefined);
  const buildingsSerials = ref([] as Array<{serial: string, role: string}>);
  const buildings = ref([] as Array<Building>);

  const changeBuildings = (value: Array<Building>) => {
    buildings.value = value;
    lastFetch.value = new Date();
  };

  const changeBuildingValue = (value: Building, key: keyof Building) => {
    const buildingFound = buildings.value.find((building: Building) => building.serial === value.serial);

    if (buildingFound) {
      (buildingFound as Record<string, unknown>)[key] = (value as unknown as Record<string, unknown>)[key];
    }
  };

  const changeBuildingsSerials = (value: Array<{serial: string, role: string}>) => {
    buildingsSerials.value = value;
  };

  return {
    buildingsSerials,
    buildings,
    changeBuildings,
    changeBuildingValue,
    changeBuildingsSerials,
  };
});
