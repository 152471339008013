/* eslint-disable import/prefer-default-export */
import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import { useBuildings } from '@/stores/modules/Buildings';
import saveState from '@/stores/utils/saveState';
import getSavedState from '@/stores/utils/getSavedState';

type UserSession = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export const useSession = defineStore('Session', () => {
  const token = ref(undefined as string | undefined);
  const refreshToken = ref(undefined as string | undefined);
  const user = ref(undefined as UserSession | undefined);
  const buildingsStore = useBuildings();
  const reason = ref(undefined as number | undefined);

  const changeToken = (value: string) => {
    token.value = value;
  };

  const changeRefreshToken = (value: string) => {
    refreshToken.value = value;
  };

  const changeUser = (value: UserSession) => {
    user.value = value;
  };

  const isLoggedIn = (): boolean => !!token.value && !!refreshToken.value && !!user.value;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const logout = (_reason: number) => {
    token.value = undefined;
    refreshToken.value = undefined;
    user.value = undefined;

    buildingsStore.changeBuildingsSerials([]);
    buildingsStore.changeBuildings([]);

    router.push({ name: 'Login', params: { reason: reason.value } });
  };

  const saveStateToWindow = () => {
    saveState('Session', {
      token: token.value,
      refreshToken: refreshToken.value,
      user: user.value,
    });
  };

  const savedValues: {
    token: string;
    refreshToken: string;
    user: UserSession;
  } = getSavedState('Session') as {
    token: string;
    refreshToken: string;
    user: UserSession;
  };

  if (savedValues) {
    changeToken(savedValues.token);
    changeRefreshToken(savedValues.refreshToken);
    changeUser(savedValues.user);
  }

  watch(token, (value) => {
    if (!value) {
      logout(0);
    }
    saveStateToWindow();
  });

  watch(refreshToken, (value) => {
    if (!value) {
      logout(0);
    }
    saveStateToWindow();
  });

  watch(user, (value) => {
    if (!value) {
      logout(0);
    }
    saveStateToWindow();
  });

  return {
    token,
    refreshToken,
    user,
    changeToken,
    changeRefreshToken,
    changeUser,
    logout,
    isLoggedIn,
  };
});
