/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from 'axios';
import qs from 'qs';
import httpInstance from '@/services/HTTP/Visualfy/http-commons';
import ServerNotAvailable from './Errors/ServerNotAvailable';
import UnauthorizedError from './Errors/UnauthorizedError';
import BadRequestError from './Errors/BadRequestError';
import ConflictError from './Errors/ConflictError';

class UserDataService {
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  getAllFromBuilding(building: string): Promise<any> {
    const filter = [
      {
        field: 'buildings',
        subField: 'building',
        operator: 'ELEM_MATCH',
        value: building,
      },
      {
        field: 'buildings',
        subField: 'role',
        operator: 'ELEM_NOT_MATCH',
        value: 'user',
      },
    ];
    return this.http.get(`/api/v1/users/?filters=${JSON.stringify(filter)}`)
      .then((result) => {
        if (result.data && result.data.status === 'success') {
          return result.data.data.items;
        }

        return [];
      })
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
              throw new UnauthorizedError();
            case 404:
              throw new ServerNotAvailable();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  getOne(id: string): Promise<any> {
    return this.http.get(`/api/v1/users/${id}`);
  }

  me(): Promise<any> {
    return this.http.get('/api/v1/users/me/profile')
      .then((result) => {
        if (result.data) {
          return result.data;
        }

        return undefined;
      })
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              throw new BadRequestError();
            case 401:
            case 404:
              throw new UnauthorizedError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  logout(): Promise<any> {
    return this.http.post('/api/v1/users/logout')
      .then(() => 204)
      .catch((error) => {
        console.log(error);
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              throw new BadRequestError();
            case 403:
              throw new UnauthorizedError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  createUser(building: string, payload: {
    firstName: string,
    lastName: string,
    email: string,
    role: string,
    notes: string | undefined,
  }): Promise<any> {
    return this.http.post(`/api/v1/users/${building}`, qs.stringify(payload))
      .then(() => 204)
      .catch((error) => {
        console.log(error);
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              throw new BadRequestError();
            case 401:
            case 403:
              throw new UnauthorizedError();
            case 409:
              throw new ConflictError();
            case 404:
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  changePassword(user: string, oldPassword: string, newPassword: string): Promise<any> {
    return this.http.put(`/api/v1/users/${user}/password`, qs.stringify({ oldPassword, newPassword }))
      .then(() => 200)
      .catch((error) => {
        console.log(error);
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              throw new BadRequestError();
            case 401:
            case 403:
              throw new UnauthorizedError();
            case 409:
              throw new ConflictError();
            case 404:
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }
}

export default new UserDataService(httpInstance);
