export type LanguageType = {
  key: string,
  text: string;
};

export const languages: Array<LanguageType> = [
  {
    key: 'es',
    text: 'Español',
  },
  {
    key: 'en',
    text: 'English',
  },
];
