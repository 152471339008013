import { createI18n } from 'vue-i18n';

const messages = {
  es: require('@/locales/es').default, // eslint-disable-line global-require, @typescript-eslint/no-var-requires
  en: require('@/locales/en').default, // eslint-disable-line global-require, @typescript-eslint/no-var-requires
};

const localeDefault: string | undefined = JSON.parse(window.localStorage.getItem('UserPreferences') || '{}').language || undefined;

const i18n = createI18n({
  legacy: false,
  locale: localeDefault || 'es',
  fallbackLocale: 'es',
  messages,
  datetimeFormats: {
    en: {
      long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      },
    },
    es: {
      long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      },
    },
  },
});

export default i18n;
