/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from 'axios';
import qs from 'qs';
import httpInstance from '@/services/HTTP/Visualfy/http-commons';
import { Filter } from './Utils/Filter';
import ServerNotAvailable from './Errors/ServerNotAvailable';
import UnauthorizedError from './Errors/UnauthorizedError';
import NotPlacesUserError from './Errors/NotPlacesUserError';
import BadRequestError from './Errors/BadRequestError';
import NotFoundError from './Errors/NotFoundError';

class AlertDataService {
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  getMany(ids: Array<string>): Promise<any> {
    const filter: Array<Filter> = [];

    ids.forEach((id) => {
      filter.push(
        {
          field: 'id',
          operator: 'CONTAINS',
          value: id,
        },
      );
    });

    return this.http.get(`/api/v1/alerts/?filters=${JSON.stringify(filter)}`)
      .then((result) => {
        if (result.data && result.data.status === 'success') {
          return result.data.data.items;
        }

        return [];
      })
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
            case 404:
              throw new UnauthorizedError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  createCustomAlert(alert: {
    building: string,
    alert: string,
    title: string,
    body: string,
    time: string,
    days: string,
  }): Promise<any> {
    return this.http.post('/api/v1/customAlerts/', qs.stringify(alert))
      .then((result) => {
        if (result) {
          return result.data.data.items;
        }

        return [];
      })
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              throw new BadRequestError();
            case 401:
              throw new UnauthorizedError();
            case 404:
              throw new NotFoundError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  getCustomAlertsFromBuilding(buildings: Array<string>): Promise<any> {
    const filter: Array<Filter> = [];

    buildings.forEach((building) => {
      filter.push(
        {
          field: 'building',
          operator: 'CONTAINS',
          value: building,
        },
      );
    });

    return this.http.get(`/api/v1/customAlerts/?filters=${JSON.stringify(filter)}`)
      .then((result) => {
        if (result.data && result.data.status === 'success') {
          return result.data.data.items;
        }

        return [];
      })
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
              throw new UnauthorizedError();
            case 404:
              throw new NotPlacesUserError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  editCustomAlert(alert: string, payload: {
    title?: string,
    body?: string,
    time?: string,
    days?: string,
  }): Promise<any> {
    return this.http.put(`/api/v1/customAlerts/${alert}`, qs.stringify(payload))
      .then(() => 200)
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              throw new BadRequestError();
            case 401:
              throw new UnauthorizedError();
            case 404:
              throw new NotFoundError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  deleteCustomAlert(alert: string): Promise<any> {
    return this.http.delete(`/api/v1/customAlerts/${alert}`)
      .then(() => 204)
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              throw new BadRequestError();
            case 401:
              throw new UnauthorizedError();
            case 404:
              throw new NotFoundError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  activateCustomAlert(alert: string): Promise<any> {
    return this.http.post(`/api/v1/customAlerts/${alert}/activate`)
      .then(() => 200)
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              throw new BadRequestError();
            case 401:
              throw new UnauthorizedError();
            case 404:
              throw new NotFoundError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }
}

export default new AlertDataService(httpInstance);
