/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from 'axios';
import httpInstance from '@/services/HTTP/Visualfy/http-commons';
import UnauthorizedError from './Errors/UnauthorizedError';
import ServerNotAvailable from './Errors/ServerNotAvailable';
import NotFoundError from './Errors/NotFoundError';

class DeviceDataService {
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  getDeviceStatus(dssSerial: string): Promise<any> {
    return this.http.get(`/api/v1/devices/${dssSerial}/status/`)
      .then((result) => {
        if (result.data && result.data.status === 'success') {
          const newObject = { serial: dssSerial, result: result.data.data.items[0] };
          return [newObject];
        }

        return [];
      })
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
              throw new UnauthorizedError();
            case 404:
              throw new NotFoundError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }
}

export default new DeviceDataService(httpInstance);
