/* eslint-disable @typescript-eslint/no-explicit-any */
import { Ref, ref } from 'vue';
import axios from 'axios';
import { useBuildings } from '@/stores/modules/Buildings';
import { useSession } from '@/stores/modules/Session';
import BuildingDataService from '@/services/HTTP/Visualfy/BuildingDataService';
import AlertDataService from '@/services/HTTP/Visualfy/AlertDataService';
import UnauthorizedError from '@/services/HTTP/Visualfy/Errors/UnauthorizedError';
import NotPlacesUserError from '@/services/HTTP/Visualfy/Errors/NotPlacesUserError';
import { Device } from '@/types/Device';
import { Alert } from '@/types/Alert';
import { Building } from '@/types/Building';
import { CustomAlert } from '@/types/CustomAlert';
import DeviceDataService from '@/services/HTTP/Visualfy/DeviceDataService';
import { useWebsiteAlert } from '@/stores/modules/WebsiteAlert';
import { useUserPreferences } from '@/stores/modules/UserPreferences';
import DemoDataService from '@/services/HTTP/Visualfy/DemoDataService';

async function loadBuildingsPrivate(): Promise<any> {
  const buildingsStore = useBuildings();
  const sessionStore = useSession();
  const websiteStore = useWebsiteAlert();
  const userPreferencesStore = useUserPreferences();
  const { buildingsSerials } = buildingsStore;
  const buildingSerials: Array<string> = buildingsSerials
    .map((building: {serial: string, role: string}) => building.serial);

  if (buildingsSerials.length === 0) {
    throw new NotPlacesUserError();
  }

  await BuildingDataService.getDevicesFromBuildings(buildingSerials)
    .then(async (result: any) => {
      if (!result) {
        throw new UnauthorizedError();
      }

      if (Array.isArray(result) && result.length > 0) {
        const devices: Array<Device> = [];
        const customAlerts: Array<CustomAlert> = [];
        const devicesAlerts: Array<string> = [];
        const sttLanguages: Array<string> = [];

        result.forEach((device: any) => {
          const deviceItem: {
            id: string,
            serial: string,
            description: string,
            type: string,
            lastConnection: string,
            state: string,
            owner: string,
            alerts: Array<Alert>
            stt: string[] | null,
          } = {
            id: device.id,
            serial: device.serial,
            description: device.description,
            type: device.type,
            lastConnection: 'dss_info.unknown',
            state: 'dss_info.unknown',
            owner: device.owner,
            alerts: [],
            stt: device.stt,
          };

          if (deviceItem.type === 'STT' && deviceItem.stt) {
            sttLanguages.push(...deviceItem.stt);
          }

          devices.push(deviceItem);

          device.alerts.forEach((alert: string) => {
            devicesAlerts.push(alert);
          });
        });

        const isStt: Map<string, boolean> = new Map();
        const sttSerials: Map<string, Array<string>> = new Map();

        devices.forEach((device) => {
          if (device.type === 'STT' && !isStt.has(device.owner)) {
            isStt.set(device.owner, true);
            sttSerials.set(device.owner, [...devices.filter((dev: Device) => dev.type === 'STT' && dev.owner === device.owner).map((dev: Device) => dev.serial)]);
          }
        });

        if (devicesAlerts.length > 0) {
          await AlertDataService.getMany(devicesAlerts)
            .then(async (alertResults: any) => {
              if (Array.isArray(alertResults) && alertResults.length > 0) {
                const forLength = alertResults.length;
                const devicesLength = devices.length;
                for (let i = 0; i < forLength; i++) {
                  const alert: {
                    id: string,
                    device: string,
                    sound: unknown,
                    color: string,
                  } = {
                    id: alertResults[i].id,
                    device: alertResults[i].device,
                    sound: alertResults[i].sound,
                    color: alertResults[i].color,
                  };

                  for (let j = 0; j < devicesLength; j++) {
                    if (devices[j].serial !== alert.device) {
                      // eslint-disable-next-line no-continue
                      continue;
                    }

                    devices[j].alerts.push(alert);
                  }
                }

                await AlertDataService.getCustomAlertsFromBuilding(buildingSerials)
                  .then((customAlertsResults: any) => {
                    if (Array.isArray(customAlertsResults) && customAlertsResults.length > 0) {
                      customAlertsResults.forEach((alert: any) => {
                        const customAlert: CustomAlert = {
                          id: alert.id,
                          building: alert.building,
                          alert: alert.alert,
                          title: alert.title,
                          body: alert.body,
                          time: alert.time,
                          days: alert.days,
                        };

                        customAlerts.push(customAlert);
                      });
                    }
                  })
                  .catch((error: Error) => {
                    if (error instanceof UnauthorizedError) {
                      sessionStore.logout(401);
                    }

                    if (error instanceof NotPlacesUserError) {
                      sessionStore.logout(403);
                    }
                  });
              }
            })
            .catch((error: Error) => {
              if (error instanceof UnauthorizedError) {
                sessionStore.logout(401);
              }

              if (error instanceof NotPlacesUserError) {
                sessionStore.logout(403);
              }
            });
        }

        const devicesStatus: Array<any> = [];
        const dssSerial: Array<string> = devices.map((device) => device.serial);
        dssSerial.forEach((serial) => {
          devicesStatus.push(DeviceDataService.getDeviceStatus(serial));
        });
        await axios.all(devicesStatus)
          .then((devicesResult) => {
            devicesResult.forEach((element) => {
              for (let i = 0; i < devices.length; i++) {
                if (devices[i].serial === element[0].serial) {
                  devices[i].lastConnection = element[0].result.CONNECTION_STATUS.LAST_DUMMY;
                  devices[i].state = element[0].result.CONNECTION_STATUS.NICE_NAME;
                  break;
                }
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });

        const demos = await Promise.allSettled(
          buildingSerials.map((serial) => DemoDataService.getDemo(serial)),
        );

        const demoMap = new Map();
        demos.forEach((demo) => {
          if (demo.status === 'fulfilled' && demo.value) {
            demoMap.set((demo.value as {building: string}).building, demo.value);
          }
        });

        await BuildingDataService.getManyBuildingsData(buildingSerials)
          .then((buildingsResult: any) => {
            const buildings: Array<Building> = [];

            if (buildingsResult.length === 0) {
              throw new NotPlacesUserError();
            }

            const buildingsLength = buildingsResult.length;
            const devicesLength = devices.length;

            for (let i = 0; i < buildingsLength; i++) {
              const demo = demoMap.has(buildingsResult[i].serial) ? demoMap.get(buildingsResult[i].serial) : null;

              // eslint-disable-next-line max-len
              const cAlerts: Array<CustomAlert> = customAlerts.filter((cAlert: CustomAlert) => cAlert.building === buildingsResult[i].serial);
              // eslint-disable-next-line max-len

              const building: Building = {
                id: buildingsResult[i].id,
                serial: buildingsResult[i].serial,
                description: buildingsResult[i].description,
                company: buildingsResult[i].company,
                devices: [],
                users: buildingsResult[i].users.length,
                alerts: [],
                customAlerts: cAlerts,
                thirdPartyApps: {
                  demo: {
                    active: !!demo,
                    primaryLogo: demo?.mainLogo || '',
                    secondaryLogo: demo?.secondaryLogo || '',
                  },
                  stt: {
                    active: isStt.has(buildingsResult[i].serial),
                    serials: sttSerials.get(buildingsResult[i].serial) || [],
                    languages: sttLanguages,
                  },
                },
                alertLogs: [],
              };

              for (let j = 0; j < devicesLength; j++) {
                if (devices[j].owner !== building.serial) {
                  // eslint-disable-next-line no-continue
                  continue;
                }

                building.devices.push(devices[j]);
              }

              buildings.push(building);
            }

            buildingsStore.changeBuildings(buildings);

            if (!userPreferencesStore.building) {
              userPreferencesStore.changeBuilding(buildings[0].serial);
            }
          })
          .catch((error: Error) => {
            if (error instanceof UnauthorizedError) {
              websiteStore.showAlert({ type: 'error', code: 'LGN-L-0004', textKey: 'login.error_401_redirect' });
              sessionStore.logout(401);
            }

            if (error instanceof NotPlacesUserError) {
              websiteStore.showAlert({ type: 'error', code: 'LGN-L-0005', textKey: 'login.error_403_redirect' });
              sessionStore.logout(403);
            }
          });
        return;
      }

      throw new NotPlacesUserError();
    });
}

async function loadBuildings(): Promise<any> {
  const buildingsStore = useBuildings();
  const { buildingsSerials, buildings } = buildingsStore;
  const buildingSerials: Array<string> = buildingsSerials
    .map((building: {serial: string, role: string}) => building.serial);

  const buildingsArray: Array<string> = buildings
    .map((building: Building) => building.serial);

  console.log('load called', buildingSerials.length !== buildingsArray.length, (buildingSerials.filter((bld) => !buildingsArray.includes(bld))).length > 0);

  if (
    buildingSerials.length !== buildingsArray.length
    || (buildingSerials.filter((bld) => !buildingsArray.includes(bld))).length > 0
  ) {
    console.log('load worked');
    await loadBuildingsPrivate();
  }
}

export default function (): { loadBuildings: () => Promise<any>, loadingBuildings: Ref<boolean> } {
  const loadingBuildings = ref(false);

  return {
    loadBuildings,
    loadingBuildings,
  };
}
