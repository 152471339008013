import axios, { AxiosInstance } from 'axios';
import { useSession } from '@/stores/modules/Session';

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL ? `${process.env.VUE_APP_API_BASE_URL}/api/v1/` : '',
  headers: {
    'Content-type': 'application/x-www-form-urlencoded',
  },
});

apiClient.interceptors.request.use((config) => {
  const { token } = useSession();

  if (token) {
    if (config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
}, (error) => Promise.reject(error));

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      useSession().logout(500);
    }

    return Promise.reject(error);
  },
);

export default apiClient;
