import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import App from './App.vue';
import router from './router';
import store from './stores';
import vuei18n from './plugins/vuei18n';

const app = createApp(App).use(Quasar, quasarUserOptions).use(store).use(router)
  .use(vuei18n)
  .mount('#app');

if (process.env.VUE_APP_TEST === 'e2e') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).app = app;
}
