/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref } from 'vue';
import { defineStore } from 'pinia';
import Notify from 'quasar/src/plugins/Notify.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import vuei18n from '@/plugins/vuei18n';

Notify.setDefaults({
  position: 'top-right',
  textColor: 'white',
  progress: true,
});

// eslint-disable-next-line import/prefer-default-export
export const useWebsiteAlert = defineStore('WebsiteAlert', () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { t } = vuei18n.global;
  const $q = useQuasar();

  const activeSign = ref({} as ((props?: {
    caption?: string;
    message?: string;
    timeout?: number;
  }) => void)
  | undefined);

  const alertQueue = ref([] as Array<unknown>);

  const showAlert = (alert: any) => {
    switch (alert.type) {
      case 'success':
        $q.notify({
          icon: 'done',
          message: t(alert.textKey),
          actions: [{ icon: 'close', color: 'white' }],
        });
        break;
      case 'info':
        $q.notify({
          icon: 'info',
          message: t(alert.textKey),
          actions: [{ icon: 'close', color: 'white' }],
        });
        break;
      case 'warning':
        $q.notify({
          icon: 'warning',
          message: t(alert.textKey),
          actions: [{ icon: 'close', color: 'white' }],
        });
        break;
      case 'error':
      default:
        $q.notify({
          icon: 'error',
          message: `${t(alert.textKey)} (${alert.code ? alert.code : 'UNK-E-0001'})`,
          actions: [{ icon: 'close', color: 'white' }],
        });
        break;
    }
    alertQueue.value.push(alert);
  };

  const showSign = (alert: any) => {
    switch (alert.type) {
      case 'info':
      default:
        activeSign.value = $q.notify({
          icon: 'info',
          message: t(alert.textKey),
          progress: false,
          timeout: 60000,
        });
        break;
    }
    alertQueue.value.push(alert);
  };

  const closeSign = () => {
    if (activeSign.value) {
      activeSign.value();
      activeSign.value = undefined;
    }
  };

  return {
    showAlert,
    showSign,
    closeSign,
  };
});
