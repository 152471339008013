import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import saveState from '@/stores/utils/saveState';
import getSavedState from '@/stores/utils/getSavedState';

// eslint-disable-next-line import/prefer-default-export
export const useUserPreferences = defineStore('UserPreferences', () => {
  const darkMode = ref(false);
  const building = ref('');
  const language = ref('es');
  const timeZone = ref('Europe/Madrid'); // Intl.DateTimeFormat().resolvedOptions().timeZone

  const changeDarkMode = (value: boolean) => {
    darkMode.value = value;
  };

  const changeBuilding = (value: string) => {
    building.value = value;
  };

  const changeLanguage = (value: string) => {
    language.value = value;
  };

  const changeTimeZone = (value: string) => {
    timeZone.value = value;
  };

  const availableTimeZones: Array<{key: string, value: string}> = [{ key: 'menu.time_zone_europe', value: 'Europe/Madrid' },
    { key: 'menu.time_zone_atlantic', value: 'Atlantic/Canary' }];

  const saveStateToWindow = () => {
    saveState('UserPreferences', {
      darkMode: darkMode.value,
      building: building.value,
      language: language.value,
      timeZone: timeZone.value,
    });
  };

  const savedValues: {
    darkMode: boolean;
    building: string;
    language: string;
    timeZone: string;
  } = getSavedState('UserPreferences') as {
    darkMode: boolean;
    building: string;
    language: string;
    timeZone: string;
  };

  if (savedValues) {
    changeDarkMode(savedValues.darkMode);
    changeBuilding(savedValues.building);
    changeLanguage(savedValues.language);
    changeTimeZone(savedValues.timeZone);
  }

  watch(darkMode, () => {
    saveStateToWindow();
  });

  watch(building, () => {
    saveStateToWindow();
  });

  watch(language, () => {
    saveStateToWindow();
  });

  watch(timeZone, () => {
    saveStateToWindow();
  });

  return {
    darkMode,
    building,
    language,
    timeZone,
    availableTimeZones,
    changeDarkMode,
    changeBuilding,
    changeLanguage,
    changeTimeZone,
  };
});
