import script from "./SVGIcon.vue?vue&type=script&setup=true&lang=ts"
export * from "./SVGIcon.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCircularProgress,QAvatar,QChip});
