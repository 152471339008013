/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import httpInstance from '@/services/HTTP/Visualfy/http-commons';
import { Building } from '@/types/Building';
import { Device } from '@/types/Device';
import UnauthorizedError from './Errors/UnauthorizedError';
import ServerNotAvailable from './Errors/ServerNotAvailable';
import { Filter } from './Utils/Filter';
import NotFoundError from './Errors/NotFoundError';

class BuildingDataService {
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  getBuildingData(building: string): Promise<any> {
    return this.http.get(`/api/v1/buildings/${building}/`)
      .then((result) => {
        if (result.data && result.data.status === 'success') {
          return result.data.data.items;
        }

        return [];
      })
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
            case 404:
              throw new UnauthorizedError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  getManyBuildingsData(buildings: Array<string>): Promise<any> {
    const filter: Array<Filter> = [];

    buildings.forEach((building) => {
      filter.push(
        {
          field: 'serial',
          operator: 'CONTAINS',
          value: building,
        },
      );
    });

    return this.http.get(`/api/v1/buildings/?filters=${JSON.stringify(filter)}`)
      .then((result) => {
        if (result.data && result.data.status === 'success') {
          return result.data.data.items;
        }

        return [];
      })
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
            case 404:
              throw new UnauthorizedError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  getDevicesFromBuildings(buildings: Array<string>): Promise<any> {
    const filter: Array<Filter> = [];

    buildings.forEach((building) => {
      filter.push(
        {
          field: 'owner',
          operator: 'CONTAINS',
          value: building,
        },
      );
    });

    return this.http.get(`/api/v1/devices/?filters=${JSON.stringify(filter)}`)
      .then((result) => {
        if (result.data && result.data.status === 'success') {
          return result.data.data.items;
        }

        return [];
      })
      .catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
            case 404:
              throw new UnauthorizedError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  restoreBuildingHueScene(building: Building): Promise<any> {
    const devicesSerials: Array<string> = building.devices.map((device: Device) => device.serial);

    const promises: Array<any> = [];

    devicesSerials.forEach((serial: string) => {
      promises.push(this.http.post(`/api/v1/devices/${serial}/command/hue`, qs.stringify({ values: { param: 'restore_scene' } })));
    });

    return axios.all(promises)
      .then(() => 200).catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
              throw new UnauthorizedError();
            case 404:
              throw new NotFoundError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }

  activateAlert(building: Building, alert: string, microphone: string): Promise<any> {
    const devicesSerials: Array<string> = building.devices.map((device: Device) => device.serial);

    const promises: Array<any> = [];

    devicesSerials.forEach((serial: string) => {
      promises.push(this.http.post(
        `/api/v1/devices/${serial}/command/activate_alert`,
        qs.stringify({ values: { alert, microphone } }),
      ));
    });

    return axios.all(promises)
      .then(() => 200).catch((error) => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
              throw new UnauthorizedError();
            case 404:
              throw new NotFoundError();
            default:
              throw new ServerNotAvailable();
          }
        }

        throw new ServerNotAvailable();
      });
  }
}

export default new BuildingDataService(httpInstance);
