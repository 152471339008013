import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { useSession } from '@/stores/modules/Session';
import vuei18n from '@/plugins/vuei18n';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      authRequired: true,
      title: 'page_titles.home_title',
    },
  },
  {
    path: '/alerts/notifications',
    name: 'CustomAlertNotifications',
    component: () => import(/* webpackChunkName: "alerts" */ '../views/CustomAlertsView.vue'),
    meta: {
      authRequired: true,
      title: 'page_titles.alerts_notifications_title',
    },
  },
  {
    path: '/alerts/services',
    name: 'CustomAlertServices',
    component: () => import(/* webpackChunkName: "alerts" */ '../views/CustomAlertsView.vue'),
    meta: {
      authRequired: true,
      title: 'page_titles.alerts_services_title',
    },
  },
  {
    path: '/alerts/closing_time',
    name: 'CustomAlertClosingTime',
    component: () => import(/* webpackChunkName: "alerts" */ '../views/CustomAlertsView.vue'),
    meta: {
      authRequired: true,
      title: 'page_titles.alerts_closing_doors_title',
    },
  },
  {
    path: '/alerts/emergency',
    name: 'CustomAlertEmergency',
    component: () => import(/* webpackChunkName: "alerts" */ '../views/CustomAlertsView.vue'),
    meta: {
      authRequired: true,
      title: 'page_titles.alerts_emergency_title',
    },
  },
  {
    path: '/alerts/loudspeaker/:serial',
    name: 'LoudspeakerAlert',
    component: () => import(/* webpackChunkName: "loudspeaker" */ '../views/LoudspeakerView.vue'),
    meta: {
      authRequired: true,
      title: 'page_titles.loudspeaker_title',
    },
  },
  {
    path: '/alertlogs',
    name: 'AlertLogs',
    component: () => import(/* webpackChunkName: "alertlogs" */ '../views/AlertLogsView.vue'),
    meta: {
      authRequired: true,
      title: 'page_titles.alert_logs_title',
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue'),
    meta: {
      authRequired: true,
      title: 'page_titles.users_title',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
    meta: {
      authRequired: true,
      title: 'page_titles.profile_title',
    },
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import(/* webpackChunkName: "demo" */ '../views/DemoView.vue'),
    meta: {
      authRequired: true,
      title: 'page_titles.demo_title',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: {
      title: 'page_titles.login_title',
    },
  },
  {
    path: '/login/resetpassword',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "login" */ '../views/ForgotPasswordView.vue'),
    meta: {
      title: 'page_titles.reset_password_title',
    },
  },
  {
    path: '/login/twofactor',
    name: 'TFAView',
    component: () => import(/* webpackChunkName: "login" */ '../views/TFAView.vue'),
    meta: {
      title: 'page_titles.two_factor_title',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some((route) => route.meta.authRequired);

  if (!authRequired) return next();

  if (useSession().isLoggedIn()) return next();

  next({ name: 'Login', query: { redirectTo: to.fullPath } });
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { t } = vuei18n.global;
const DEFAULT_TITLE = 'Visualfy Places';

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = `${t(`${to.meta.title}`)} - ${DEFAULT_TITLE}`;
  } else {
    document.title = DEFAULT_TITLE;
  }
});

export default router;
